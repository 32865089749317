import { SubscriptionResponseV2 } from '@plugsurfing/cdm-api-client';
import { combineEpics } from 'redux-observable';
import { mergeMapEpic } from 'redux/utils';
import CDMServiceV2 from 'services/CDMServiceV2';
import * as actions from './actions';

const fetchSubscriptionsEpic = mergeMapEpic({
  asyncAction: actions.fetchSubscriptions,
  api: ({ payload: { ownerId, ownerType } }) =>
    CDMServiceV2.subscriptionsV2Client.getSubscriptionByOrganizationIdUsingGET(ownerId, ownerType, true),
  filter: (action, state) => {
    const { ownerId, ownerType } = action.payload;
    const { subscriptionsByOrgId, subscriptionsByUserId } = state.value.subscriptionsV2;
    switch (ownerType) {
      case SubscriptionResponseV2.OwnerTypeEnum.ORGANIZATION:
        return subscriptionsByOrgId[ownerId] === undefined;
      case SubscriptionResponseV2.OwnerTypeEnum.USER:
        return subscriptionsByUserId[ownerId] === undefined;
      default:
        return false;
    }
  },
});

export default combineEpics(fetchSubscriptionsEpic);
