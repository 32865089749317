import { Customer } from '@plugsurfing/cdm-api-client';
import { Badge, Box, Heading, ListItem, UnorderedList } from '@plugsurfing/plugsurfing-design';
import { CdKeyValue, CdLink } from 'components/design-elements';
import {
  chargingSessionsLink,
  customerLinkV2,
  organizationLink,
  productOrdersLink,
  reportedProblemsLink,
  subscriptionsLink,
} from 'config/links';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/formatters';
import { getNameV2 } from 'utils/forms';

export const UserPreview = ({ user }: { user: Customer }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Heading size="3xs" mb="m">
        {t('generalInformation')}
      </Heading>
      <CdKeyValue label="User Email">{user?.email}</CdKeyValue>
      <CdKeyValue label="Name">{user && getNameV2(user)}</CdKeyValue>
      <CdKeyValue label="Organization">
        <CdLink link={organizationLink(user?.organization)} />
      </CdKeyValue>
      <CdKeyValue label="Last active">{user && formatDateTime(user.lastActive, 'dd LLL yyyy HH:mm')}</CdKeyValue>
      <CdKeyValue label="Status">
        <Badge>{user?.status}</Badge>
      </CdKeyValue>
      <CdKeyValue label="Is system user">
        <Badge>{user?.systemUser ? 'Yes' : 'No'}</Badge>
      </CdKeyValue>
      {!user.systemUser && (
        <>
          <Heading size="3xs" mt="m">
            {t('link')}
          </Heading>
          <UnorderedList>
            <ListItem>
              <CdLink link={chargingSessionsLink(customerLinkV2(user))} />
            </ListItem>
            <ListItem>
              <CdLink link={productOrdersLink(customerLinkV2(user))} />
            </ListItem>
            <ListItem>
              <CdLink link={subscriptionsLink(customerLinkV2(user))} />
            </ListItem>
            <ListItem>
              <CdLink link={reportedProblemsLink(customerLinkV2(user))} />
            </ListItem>
          </UnorderedList>
        </>
      )}
    </Box>
  );
};
