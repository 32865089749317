import {
  ChargePointSearchResult,
  ChargingKey,
  Customer,
  ExternalEvseHeaderView,
  Organization,
} from '@plugsurfing/cdm-api-client';
import { MenuItem } from 'components/design-elements/CdMainMenu/MenuItems';
import ChargingStationService, {
  ChargingStationRequest,
  ExternalChargePointSearchRequest,
} from 'services/ChargingStationService';
import CustomersService, { CustomerSearchRequest } from 'services/CustomersService';
import OrganizationV2Service, { OrganizationSearchRequest } from 'services/OrganizationV2Service';
import { EntityScope } from 'views/powerSearch/utils/useCombinedSearch';

const fetchUsers = (request: CustomerSearchRequest, scope?: EntityScope) => [
  CustomersService.search(request, 3)
    .then(res =>
      res.content.map<SearchResult>(d => ({
        type: 'customer',
        data: d,
        count: res.totalItems,
        scope,
        searchedBy: 'email',
      })),
    )
    .catch(() => []),
];

const fetchExternalChargePoints = (request: ExternalChargePointSearchRequest, scope?: EntityScope) => {
  return [
    ChargingStationService.searchExternalChargingStations(request, 3)
      .then(res =>
        res.content.map<SearchResult>(d => ({
          type: 'external-chargepoint',
          data: d,
          count: res.totalItems,
          scope,
          searchedBy: 'evseid',
        })),
      )
      .catch(() => []),
  ];
};
const fetchInternalChargePoints = (request: ChargingStationRequest, scope?: EntityScope) => [
  ChargingStationService.search({ searchRequest: request.searchRequest, assetName: request.assetName }, 3)
    .then(res =>
      res.content.map<SearchResult>(d => ({
        type: 'internal-chargepoint',
        data: d,
        count: res.totalItems,
        scope,
        searchedBy: 'assetName',
      })),
    )
    .catch(() => []),
];

const fetchOrganizations = (request: OrganizationSearchRequest, scope?: EntityScope) => [
  OrganizationV2Service.search({ qName: request.qName, organizationId: request.organizationId }, 3)
    .then(res =>
      res.content.map<SearchResult>(d => ({
        type: 'organization',
        data: d,
        count: res.totalItems,
        scope,
        searchedBy: 'name',
      })),
    )
    .catch(() => []),
];

const patterns = {
  email: /.*@.*/,
  stringWithoutAtOrAsterisk: /^[^*@]+$/,
  hasAsterisk: /.*\*+.*/,
  hasDash: /.*-+.*/,
  anyString: /.*/,
};

const organizationPTAMap: ActionMap = {
  users: {
    patterns: [patterns.email, patterns.stringWithoutAtOrAsterisk],
    action: (query, userOrgId, scope) => fetchUsers({ email: query, organizationId: scope?.data.id }, scope),
  },
};

const patternToActionMap: ActionMap = {
  users: {
    patterns: [patterns.email, patterns.stringWithoutAtOrAsterisk],
    action: (query, userOrgId, scope) =>
      fetchUsers({ email: query, organizationId: scope?.data.id, includeSystemUsers: true }, scope),
  },
  externalChargePoints: {
    patterns: [patterns.hasAsterisk],
    action: (query, userOrgId, scope) =>
      fetchExternalChargePoints({ evseId: query, capabilities: [], status: [] }, scope),
  },
  internalChargePoints: {
    patterns: [patterns.stringWithoutAtOrAsterisk],
    action: (query, userOrgId, scope) =>
      fetchInternalChargePoints(
        {
          assetName: query,
          searchRequest: { operatorIds: scope?.data.id ? [scope?.data.id] : undefined, adminStatuses: [] } as any,
        },
        scope,
      ),
  },
  organizations: {
    patterns: [patterns.stringWithoutAtOrAsterisk],
    action: (query, userOrgId, scope) => fetchOrganizations({ qName: query, organizationId: userOrgId }, scope),
  },
};

export const matchActions = (query: string, scope?: EntityScope) => {
  let actionMap = patternToActionMap;

  if (scope?.type === 'organization') {
    actionMap = { ...actionMap, ...organizationPTAMap };
  }

  const actions = Object.entries(actionMap).filter(([, value]) =>
    value.patterns.map(pattern => pattern.test(query)).includes(true),
  );
  return actions.map(action => action[1]);
};

export type SearchResult =
  | { type: 'customer'; data: Customer; count: number; searchedBy: string; scope?: EntityScope }
  | { type: 'charging-key'; data: ChargingKey; count: number; searchedBy: string; scope?: EntityScope }
  | {
      type: 'external-chargepoint';
      data: ExternalEvseHeaderView;
      count: number;
      searchedBy: string;
      scope?: EntityScope;
    }
  | {
      type: 'internal-chargepoint';
      data: ChargePointSearchResult;
      count: number;
      searchedBy: string;
      scope?: EntityScope;
    }
  | { type: 'link'; data: MenuItem & { translated: string }; count: number; searchedBy: string; scope?: EntityScope }
  | { type: 'organization'; data: Organization; count: number; searchedBy: string; scope?: EntityScope };

type ActionMap = Record<
  string,
  {
    patterns: RegExp[];
    action: (query: string, userOrgI: string, scope?: EntityScope) => Promise<SearchResult[]>[];
  }
>;
