import { Organization } from '@plugsurfing/cdm-api-client';
import type { PriceProfileContent } from 'cdm-api-client/v4PricesApi';
import { LocalesKey } from 'i18n';
import { i18n } from 'i18next';
import {
  OrganizationCreateRequest as OrganizationCreateRequestModel,
  Organization as OrganizationModel,
  OrganizationUpdateRequest as OrganizationUpdateRequestModel,
} from 'models/organization';
// @ts-ignore
import { compose } from 'react-redux';

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export type InjectedProps<T> = T extends InferableComponentEnhancerWithProps<infer Props, infer _> ? Props : never;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
    // Runtime variables
    _PS_: {
      env: NodeJS.ProcessEnv;
    };
  }
}

declare module '@plugsurfing/cdm-api-client' {
  interface UserGroupAddUserRequest {
    // @ts-ignore
    email?: string;
    // @ts-ignore
    userVirtualIds?: string[];
  }

  interface ChargingKeyRequest {
    // @ts-ignore
    validFrom?: number;
    // @ts-ignore
    validTo?: number;
    // @ts-ignore
    licensePlate?: string;
    // @ts-ignore
    referenceId?: string;
    // @ts-ignore
    fleetId?: string;
  }

  interface ChargingKeyUpdateRequest {
    // @ts-ignore
    licensePlate?: string;
    // @ts-ignore
    referenceId?: string;
  }

  interface BillingPlan {
    // @ts-ignore
    dayOfInvoice?: DayOfInvoice;
  }

  interface Customer {
    addresses: GenericAddress[];
    // @ts-ignore
    billingAddress?: BillingAddress;
    created: number;
    // @ts-ignore
    customFields?: object;
    customerType: Customer.CustomerTypeEnum;
    email: string;
    evModelIds: string[];
    id: string;
    language: Customer.LanguageEnum;
    lastActive: number;
    licensePlates: string[];
    marketingConsent: MarketingConsentSettings;
    organization: OrganizationBase;
    phoneNumber: string;
    phoneNumbers: PhoneNumber[];
    systemUser: boolean;
    updated: number;
    userGroups: UserGroupWithOrganizationId[];
    version: number;
    firstName?: string;
    lastName?: string;
  }

  interface PayloadObjectResponse {
    parentId: string;
    payload: any;
  }

  interface JsonSchemaProperty<T> {
    minLength?: number;
    maxLength?: number;
    enum?: any[];
    prefix?: string;
    title?: LocalesKey;
    default?: T;
    description: string;
    type: string;
    disabled?: boolean;
  }

  interface JsonSchemaStringProperty extends JsonSchemaProperty<string> {
    type: 'string';
    format?: 'email';
    component?: 'textarea' | 'input';
    pattern?: string;
  }

  interface JsonSchemaIntegerProperty extends JsonSchemaProperty<number> {
    type: 'integer';
  }

  interface JsonSchemaObjectProperty extends JsonSchemaProperty<number> {
    type: 'object';
    properties: { [key: string]: JsonSchemaStringProperty | JsonSchemaIntegerProperty | JsonSchemaEnumProperty };
  }

  interface JsonSchemaEnumProperty extends JsonSchemaProperty<string> {
    prefix: string;
    enum: string[];
  }

  interface JsonSchema {
    $schema: 'http://json-schema.org/draft-04/schema#';
    type: 'object';
    required?: string[];
    properties: {
      [key: string]:
        | JsonSchemaStringProperty
        | JsonSchemaIntegerProperty
        | JsonSchemaEnumProperty
        | JsonSchemaObjectProperty;
    };
  }

  interface AgreementCreateRequest {
    // @ts-ignore
    assetOwnerPayee?: AgreementCreateRequest.AssetOwnerPayeeEnum;
  }

  interface TemplateEnabledFeatureParsed {
    description: string;
    id: string;
    name: string;
    schema: JsonSchema;
    type: TemplateEnabledFeature.TypeEnum;
  }

  interface Template {
    feature: string;
    locale: string;
    organizationId: string;
    type: Template.TypeEnum;
  }

  interface EmailTemplate extends Template {
    senderEmail?: string;
    subjectTemplate?: string;
    bodyTemplate?: string;
    useCustomerIOTemplate?: boolean;
    customerIOTemplate?: number;
  }
  interface ReceiptTemplate extends Template {
    receiptTemplate: string;
  }

  export interface BrandConfig {
    cognitoPrefix: string;
    className?: string;
    pageTitle?: string;
    logotype: string;
    colors: BrandingColors;
  }

  export interface BrandingColors {
    primary: string;
    primaryVariant: string;
    secondary: string;
    secondaryVariant: string;
    background: string;
    surface: string;
    error: string;
    onPrimary: string;
    onSecondary: string;
    onBackground: string;
    onSurface: string;
    onError: string;
    success: string;
    warning: string;
    links: string;
  }

  export interface BrokerInvoiceTransaction {
    transactionPaymentMethod?: BrokerInvoicePaymentMethod;
  }

  interface EmpRoamingIntegrationDetails {
    // @ts-ignore
    mainCpo?: OrganizationWithExternalId;
    // @ts-ignore
    oicpCpoInfo: OicpCpoInfo[];
  }

  export interface SearchEmpRoamingIntegrationsRequest {
    // @ts-ignore
    cpoExternalIdsFilter?: string[];
  }

  // @ts-ignore
  export interface CustomPullJobSearchFilter extends PullJobSearchFilter {
    dataType?: string;
    runState?: string;
  }

  interface OrganizationsV2Api {
    /**
     * Get all the organization modules the authorized user can assign
     *
     * Route allows fetching all the organization modules that the authorized user can assign when creating or updating organizations
     */
    getOrganizationModulesUsingGET(): Promise<OrganizationModuleDef[]>;

    getOrganizationUsingGET(organizationId: string): Promise<OrganizationModel>;

    createOrganizationUsingPOST(request: OrganizationCreateRequestModel): Promise<OrganizationModel>;

    getAccessibleOrganizationsUsingGET(
      organizationId: string,
      fetchPrevious?: boolean,
      limit?: number,
      sortField?: string,
      sortFieldCursor?: {
        [key: string]: string;
      },
      sortFieldSortOrder?: string,
      idField?: string,
      idFieldCursor?: string,
      idFieldSortOrder?: string,
      modules?: string[],
      qName?: string,
      qExternalId?: string,
      qAdvancedSearch?: string,
    ): Promise<CustomPageResponseOrganization>;

    updateOrganizationUsingPUT(
      organizationId: string,
      request: OrganizationUpdateRequestModel,
    ): Promise<OrganizationModel>;

    getAssociatedCposUsingGET(
      empId: string,
      modules?: string[],
      fetchPrevious?: boolean,
      limit?: number,
      sortField?: string,
      sortFieldCursor?: {
        [key: string]: string;
      },
      sortFieldSortOrder?: string,
      idField?: string,
      idFieldCursor?: string,
      idFieldSortOrder?: string,
      qName?: string,
      qExternalId?: string,
    ): Promise<CustomPageResponseOrganization>;

    getAssociatedEmpsUsingGET(
      cpoId: string,
      modules?: string[],
      fetchPrevious?: boolean,
      limit?: number,
      sortField?: string,
      sortFieldCursor?: {
        [key: string]: string;
      },
      sortFieldSortOrder?: string,
      idField?: string,
      idFieldCursor?: string,
      idFieldSortOrder?: string,
      qName?: string,
      qExternalId?: string,
    ): Promise<CustomPageResponseOrganization>;
  }

  interface ConnectorChargingSession {
    sessionId: string;
    startTime: number;
    stopTime: number;
  }

  interface SessionCharger {
    address?: string;
    city?: string;
    countryCode?: string;
    evseId?: string;
    internalId?: string;
    locationId?: string;
    internalEvseUid?: string;
    name?: string;
    operator?: SessionOrganization;
    postalCode?: string;
    powerType?: string;
  }

  interface SystemUserUpdateRequestV2 {
    // @ts-ignore
    billingAddress?: BillingAddress;
    // @ts-ignore
    phoneNumbers?: PhoneNumberV2[];
  }

  interface CustomChargingSessionList {
    chargingSessions?: ConnectorChargingSession[];
  }

  interface ChargePointControllerApi {
    getActiveSessionsByConnectorIdUsingGET(connectorId: string): Promise<CustomChargingSessionList>;

    /**
     * getChargePointStatus
     *
     * @param id id
     */
  }

  // Remove 2025 when CDM-API/develop is back in business
  interface ApiCreateBillingPlanRequest {
    name: string;
  }

  interface ConnectorGroupRef {
    type: string;
  }
  interface InternalConnectorRef {
    type: string;
  }
  interface ExternalConnectorRef {
    type: string;
  }

  type ConnectorRef = ConnectorGroupRef | InternalConnectorRef | ExternalConnectorGroupRef;

  interface Payer {
    // @ts-ignore
    id?: string;
  }

  interface AssociationCreateRequest {
    // @ts-ignore
    profileId: string;
    payer: Payer;
    // @ts-ignore
    connector: ConnectorRef;
    // @ts-ignore
    validFrom?: number;
    // @ts-ignore
    validTo?: number;
    // @ts-ignore
    supplierId?: string;
  }

  interface ClassificationLabelsAvailable {
    // @ts-ignore
    organizationLabels?: OrgConfigPayloadSessionClassificationConfiguration;
    // @ts-ignore
    platformLabels?: OrgConfigPayloadSessionClassificationConfiguration;
  }

  interface RuleResponse {
    condition: string;
  }

  interface ExternalLocationView {
    // @ts-ignore
    dataSource?: {
      protocol: string;
      version: string;
      id: string;
    };
    // @ts-ignore
    cpoOrgId?: string;
    // @ts-ignore
    openingTimes?: HoursView;
  }

  interface OrganizationWithExternalId {
    // @ts-ignore
    name?: string;
    // @ts-ignore
    id?: string;
  }

  export interface CustomPageResponseOrganization {
    content: OrganizationModel[];
    pagination: Pagination;
    totalItems: number;
  }

  interface CustomAssociationResponse extends AssociationResponse {
    profileContent: PriceProfileContent | undefined;
  }

  interface ErrorScenarioOptionalPropertyFix {
    description?: string;
    priority?: ErrorScenarioResponse.PriorityEnum;
    vendorErrorCode?: string;
  }

  export type ErrorScenarioResponseFix = Omit<ErrorScenarioResponse, 'description' | 'priority' | 'vendorErrorCode'> &
    ErrorScenarioOptionalPropertyFix;

  export type ErrorScenarioListItemFix = Omit<ErrorScenarioListItem, 'description' | 'priority' | 'vendorErrorCode'> &
    ErrorScenarioOptionalPropertyFix;
}

declare module 'react-i18next' {
  export function useTranslation(): { t: (key: LocalesKey, options?: any) => string; i18n: i18n };
}

export enum EntityOwnerType {
  ORGANIZATION = 'ORGANIZATION',
  USER = 'USER',
}

export const enum RoleEnum {
  ADMINISTRATOR = 'administrator',
  CUSTOMER_SERVICE = 'customer_service',
  FINANCE_ANALYST = 'finance_analyst',
  OPERATIONS_MANAGER = 'operations_manager',
  GENERAL_MANAGER = 'general_manager',
  PERSONAL_DATA_MANAGER = 'personal_data_manager',
  CHARGE_POINT_SITE_OWNER = 'charge_point_site_owner',
  CHARGER_MANUFACTURER = 'charger_manufacturer',
  B2B_MANAGER = 'b2b_manager',
  PLATFORM_ADMINISTRATOR = 'platform_administrator',
  FIRST_REAL_CUSTOM_ROLE = 'first_real_custom_role',
  PRODUCT_ORDER_MANAGER = 'product_order_manager',
  CUSTOMER_SERVICE_LITE = 'customer_service_lite',
  FINANCE_ADMIN = 'finance_admin',
  SUPERVISOR = 'supervisor',
  DRIVE_API_MANAGER = 'drive_api_manager',
  DRIVE_API_USER = 'drive_api_user',
  ROAMING_MANAGER = 'roaming_manager',
  NETWORK_MANAGER = 'network_manager',
}

export enum RoamingChannelEnum {
  CDMC = 'INTERNAL',
  ALLEXTERNAL = 'ALL_EXTERNAL',
}

export enum AssociationCustomerEnum {
  USER_GROUP = 'USER_GROUP',
  PUBLIC = 'PUBLIC',
  EMP = 'EMP',
  ADHOC = 'ADHOC',
}

export enum ModuleType {
  NONE = 'NONE',
  B2B = 'B2B',
}

export interface OrganizationModuleDef {
  id: string;
  name: string;
  type: ModuleType;
  abbreviation: Organization.ModulesEnum;
}
