import { cdmApi as api } from 'redux/slices/cdmApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getAllTariffsUsingGet: build.query<GetAllTariffsUsingGetApiResponse, GetAllTariffsUsingGetApiArg>({
      query: queryArg => ({
        url: `/v1/subscriptions/tariffs/organizations/${queryArg.organizationId}`,
        params: { owner: queryArg.owner },
      }),
    }),
    getAllOwnersUsingGet: build.query<GetAllOwnersUsingGetApiResponse, GetAllOwnersUsingGetApiArg>({
      query: () => ({ url: `/v1/subscriptions/tariffs/owners` }),
    }),
    getTariffByIdUsingGet: build.query<GetTariffByIdUsingGetApiResponse, GetTariffByIdUsingGetApiArg>({
      query: queryArg => ({ url: `/v1/subscriptions/tariffs/${queryArg.id}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v1SubscriptionsApi };
export type GetAllTariffsUsingGetApiResponse = /** status 200 OK */ TariffResponse[];
export type GetAllTariffsUsingGetApiArg = {
  /** organizationId */
  organizationId: string;
  /** owner */
  owner?: 'IONITY';
};
export type GetAllOwnersUsingGetApiResponse = /** status 200 OK */ 'IONITY'[];
export type GetAllOwnersUsingGetApiArg = void;
export type GetTariffByIdUsingGetApiResponse = /** status 200 OK */ TariffResponse;
export type GetTariffByIdUsingGetApiArg = {
  /** id */
  id: string;
};
export type TariffResponse = {
  active: boolean;
  created: number;
  id: string;
  name: string;
  organizationId: string;
  owner: 'IONITY';
  updated: number;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export const { useGetAllTariffsUsingGetQuery, useGetAllOwnersUsingGetQuery, useGetTariffByIdUsingGetQuery } =
  injectedRtkApi;
