import Link from 'models/Link';
import { getNameV2 } from 'utils/forms/address';
import { AUTHRESULTREAD, CUSTOMERREAD, PRODUCTREAD, SUBSCRIPTIONREAD, USERGROUPSREAD } from 'utils/roles';
import { SYSTEM_USER } from '../links';
import { DynamicLinkProps, DynamicLinkPropsV2, ROOT, dynamicLinkParams } from './common';

export const END_CUSTOMERS = () =>
  Link.static({
    nameLocaleKey: 'endCustomers',
    pathPart: 'end-customers',
    parent: ROOT(),
  });

export const createCustomersLink = (parent: Link = END_CUSTOMERS()) =>
  Link.static({
    nameLocaleKey: 'customers',
    pathPart: 'customers',
    parent,
    allowedPrivileges: [CUSTOMERREAD],
  });

export const CUSTOMERS = () => createCustomersLink();

export const USER_GROUPS = () =>
  Link.static({
    nameLocaleKey: 'customerGroups',
    pathPart: 'user-groups',
    parent: END_CUSTOMERS(),
    allowedPrivileges: [USERGROUPSREAD],
  });

export const customerLinkV2 = (
  customer: DynamicLinkPropsV2 & { email?: string; externalId?: string },
  emailAsName?: boolean,
  systemUser?: boolean,
  parent?: Link,
): Link => {
  const name = getNameV2(customer);
  const { email } = customer || {};
  const emailOrName = name.length > 0 ? name : email || '';
  return new Link({
    name: emailAsName ? email || customer?.externalId || '' : emailOrName,
    pathPart: `${customer?.id}`,
    parent: systemUser ? SYSTEM_USER(parent) : createCustomersLink(),
  });
};

export const dynamicCustomerLink = ({ id, name }: DynamicLinkProps): Link =>
  new Link({
    name,
    pathPart: `${id}`,
    parent: CUSTOMERS(),
  });

export const userGroupLink = (
  userGroup: DynamicLinkPropsV2 = { id: ':userGroupId', name: '' },
  organization: DynamicLinkPropsV2 = { id: ':organizationId', name: '' },
) =>
  new Link({
    name: userGroup.name || '',
    pathPart: userGroup.id,
    parent: userGroupOrganizationLink(organization),
  });

export const userGroupOrganizationLink = ({ id, name }: DynamicLinkPropsV2 = dynamicLinkParams) =>
  new Link({
    name: name || '',
    pathPart: id,
    parent: USER_GROUPS(),
  });

export const subscriptionsLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'subscriptions',
    pathPart: 'subscriptions',
    parent,
    allowedPrivileges: [SUBSCRIPTIONREAD],
  });

export const browseSubscriptionTemplatesLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'subscriptionsBrowse',
    pathPart: 'browse-subscription-templates',
    parent,
    allowedPrivileges: [SUBSCRIPTIONREAD],
  });

export const productOrdersLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'productOrders',
    pathPart: 'product-orders',
    parent,
    allowedPrivileges: [PRODUCTREAD],
  });

export const userChargingAuthResultsLink = (parent: Link): Link =>
  Link.static({
    nameLocaleKey: 'chargingAuthResults',
    pathPart: 'charging-auth-results',
    parent,
    allowedPrivileges: [AUTHRESULTREAD],
  });
