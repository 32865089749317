import {
  Box,
  Button,
  HStack,
  Table,
  TableProps,
  TableScrollContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from '@plugsurfing/plugsurfing-design';
import { ColGroups } from 'components/design-elements/CdTable/anatomy/CdColGroups';
import { CdTableHeaderColumn } from 'components/design-elements/CdTable/anatomy/CdTableHeaderColumn';
import CdTableScrollShadow from 'components/design-elements/CdTable/anatomy/CdTableScrollShadow';
import StickyHeader from 'components/design-elements/CdTable/anatomy/StickyHeader';
import { ACTIONS_COLUMN_ID } from 'components/design-elements/CdTable/anatomy/constants';
import { ExtraColumnOptions } from 'components/design-elements/CdTable/anatomy/useColumns';
import { ColumnRefMap, useResizableColumns } from 'components/design-elements/CdTable/anatomy/useResizableColumns';
import { ComponentType, Fragment, ReactNode, RefObject, useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IdType, Row, TableInstance } from 'react-table';
import { LayoutContainerContext } from 'views/LayoutContainer';
import styles from './styles.module.scss';

const EMPTY_TABLE_ROWS = 5;

export type SideComponentRenderer<T extends object> = ComponentType<{ model: T }>;

export interface CdTableTemplateProps<T extends object> extends TableProps {
  loading: boolean;
  instance: TableInstance<T>;
  extraColumnOptions: Map<IdType<T>, ExtraColumnOptions>;
  RowComponent: ComponentType<{
    table: TableInstance<T>;
    row: Row<T>;
    containerRef: RefObject<HTMLElement>;
    tableRef: RefObject<HTMLTableElement>;
    loading: boolean;
  }>;
  SideComponent?: SideComponentRenderer<T>;
  paginationComponent?: ReactNode;
  error?: unknown;
  noDataText?: string;
  onTryAgain?: () => void;
  onSetColumnSizes: (sizes?: Record<string, number>) => void;
  stickyColumnCount: number;
  disableTopBorderRadius: boolean;
  disableBottomBorderRadius: boolean;
}

export function CdTableTemplate<T extends object>({
  loading,
  instance,
  extraColumnOptions,
  RowComponent,
  SideComponent,
  error,
  noDataText,
  onTryAgain,
  onSetColumnSizes,
  stickyColumnCount,
  disableBottomBorderRadius,
  disableTopBorderRadius,
  ...tableProps
}: CdTableTemplateProps<T>) {
  const { t } = useTranslation();
  const colRefs = useRef<ColumnRefMap>({});
  const [handleResizeColumn, handleEndResizeColumn] = useResizableColumns(
    instance.visibleColumns,
    colRefs,
    onSetColumnSizes,
  );
  const { isMobile } = useContext(LayoutContainerContext);
  const handleResetResizeColumn = useCallback(() => onSetColumnSizes(undefined), [onSetColumnSizes]);

  const tableContainerRef = useRef<HTMLElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const stickyColumnRef = useRef<HTMLTableCellElement | null>(null);
  const shouldShowSideComponent = SideComponent && !isMobile && instance.selectedFlatRows.length > 0;
  const tHead = (
    <Thead>
      {instance.headerGroups.map((headerGroup, index) => (
        <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id ?? index}>
          {headerGroup.headers.map((col, i: number, arr) => {
            return (
              <CdTableHeaderColumn<T>
                key={col.id}
                column={col}
                index={i}
                extraOptions={extraColumnOptions.get(col.id)}
                isLast={arr[i + 1] === undefined || arr[i + 1].id === ACTIONS_COLUMN_ID}
                stickyColumnCount={stickyColumnCount}
                onResize={handleResizeColumn}
                onResizeEnd={handleEndResizeColumn}
                onReset={handleResetResizeColumn}
                stickyColumnRef={i === stickyColumnCount - 1 ? stickyColumnRef : null}
              />
            );
          })}
        </Tr>
      ))}
    </Thead>
  );
  const scrollShadow = (
    <CdTableScrollShadow
      containerRef={tableContainerRef}
      tableRef={tableRef}
      stickyColumnRef={stickyColumnRef}
      stickyColumnCount={stickyColumnCount}
    />
  );
  // On computer: try to fit the container width
  // On mobile: let it scroll horizontally
  const isFixedTableLayout = !isMobile;

  return (
    <HStack alignItems="stretch" gap={0}>
      <TableScrollContainer
        minW={shouldShowSideComponent ? undefined : '100%'}
        {...(disableTopBorderRadius ? { borderTopRadius: 0 } : {})}
        {...(disableBottomBorderRadius ? { borderBottomRadius: 0 } : {})}
        position="relative"
        ref={el => {
          tableContainerRef.current = el?.firstElementChild instanceof HTMLElement ? el.firstElementChild : null;
        }}
        sx={{ '& > div': { display: 'flex', alignItems: 'start' } }}
      >
        <Table
          {...tableProps}
          {...instance.getTableProps()}
          bg="white"
          variant="rows"
          overflow={'auto'}
          sx={{
            tableLayout: isFixedTableLayout ? 'fixed' : 'auto',
          }}
          data-loading-text={t('loading')}
          ref={tableRef}
          highlightRowsOnHover={!error && !loading && instance.data.length !== 0}
          borderRadius={0}
        >
          <ColGroups instance={instance} colRefs={colRefs} extraColumnOptions={extraColumnOptions} />
          {tHead}

          <Tbody {...instance.getTableBodyProps()}>
            {!loading && error !== undefined ? (
              <ErrorRows instance={instance} onTryAgain={onTryAgain} />
            ) : instance.data.length === 0 ? (
              loading ? (
                <SkeletonRows instance={instance} />
              ) : (
                <NoDataRows text={noDataText} instance={instance} />
              )
            ) : (
              instance.page.map(row => (
                <RowComponent
                  key={row.id}
                  table={instance}
                  row={row}
                  containerRef={tableContainerRef}
                  tableRef={tableRef}
                  loading={loading}
                />
              ))
            )}
          </Tbody>
        </Table>
        {scrollShadow}
        {
          // TODO: To make sticky header work for mobile, need consideration for the top bar
          !isMobile && (
            <StickyHeader
              isFixedTableLayout={isFixedTableLayout}
              containerRef={tableContainerRef}
              colGroups={<ColGroups instance={instance} extraColumnOptions={extraColumnOptions} />}
              tHead={tHead}
              scrollShadow={scrollShadow}
            />
          )
        }
      </TableScrollContainer>
      {shouldShowSideComponent && (
        <Box
          w="36%"
          flexGrow={0}
          flexShrink={0}
          p="m"
          bg="surface.primary"
          borderWidth="thin"
          borderStyle="solid"
          borderColor="border.primary"
          ml="-1px"
        >
          <SideComponent model={instance.selectedFlatRows[0]?.original} />
        </Box>
      )}
    </HStack>
  );
}

interface SkeletonRowsProps<T extends object> {
  instance: TableInstance<T>;
}

function SkeletonRows<T extends object>({ instance }: SkeletonRowsProps<T>) {
  return (
    <>
      {Array.from({ length: EMPTY_TABLE_ROWS }).map((_, i) => (
        <tr key={i} className={[styles.row, styles.loading].join(' ')}>
          {instance.allColumns.map(col => {
            if (!col.isVisible) {
              return <Fragment key={col.id} />;
            }

            return (
              <td key={col.id} className={styles.cell}>
                <Box h="1rem" />
              </td>
            );
          })}
        </tr>
      ))}
    </>
  );
}

interface NoDataRowsProps {
  text?: string;
  instance: TableInstance<any>;
}

function NoDataRows({ text, instance }: NoDataRowsProps) {
  const { t } = useTranslation();

  return <MessageRows instance={instance}>{text ?? t('noDataText')}</MessageRows>;
}

interface ErrorRowsProps {
  instance: TableInstance<any>;
  onTryAgain?: () => void;
}

function ErrorRows({ instance, onTryAgain }: ErrorRowsProps) {
  const { t } = useTranslation();

  return (
    <MessageRows instance={instance}>
      {t('somethingWentWrongAccessingData')}
      {onTryAgain !== undefined && (
        <Button ml="xs" variant="compactAccent" verticalAlign="baseline" onClick={onTryAgain}>
          {t('tryAgain')}
        </Button>
      )}
    </MessageRows>
  );
}

interface MessageRowsProps {
  instance: TableInstance;
  children?: ReactNode;
}

function MessageRows({ children, instance }: MessageRowsProps) {
  const messageRowIndex = Math.floor(EMPTY_TABLE_ROWS / 2);

  return (
    <>
      {Array.from({ length: EMPTY_TABLE_ROWS }).map((_, index) => (
        <Tr key={index}>
          <Td
            textAlign="center"
            colSpan={instance.headerGroups.map(g => g.headers.length).reduce((a: number, b: number) => a + b, 0)}
            borderBottomColor="transparent"
            color="text.tertiary"
          >
            {index === messageRowIndex && (
              <Box position="absolute" left={0} w="100%">
                {children}
              </Box>
            )}
            <Box h="1rem" />
          </Td>
        </Tr>
      ))}
    </>
  );
}
