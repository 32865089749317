import { PageResponseCustomer, PagingCursor } from '@plugsurfing/cdm-api-client';
import CDMService from './CDMService';

export interface CustomerSearchRequest {
  organizationId?: string;
  email?: string;
  externalId?: string;
  userGroupId?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  includeSystemUsers?: boolean;
}

export default {
  ...CDMService.customersV2Client,
  search: (
    request: CustomerSearchRequest,
    count?: number,
    cursor?: PagingCursor,
    fetchPrevious = false,
  ): Promise<PageResponseCustomer> => {
    const { sortField, sortFieldCursor, sortFieldSortOrder, idField, idFieldCursor, idFieldSortOrder } = cursor || {
      sortField: undefined,
      sortFieldCursor: undefined,
      sortFieldSortOrder: undefined,
      idField: undefined,
      idFieldCursor: undefined,
      idFieldSortOrder: undefined,
    };

    const { email, userGroupId, externalId, organizationId, firstName, lastName, status, includeSystemUsers } = request;

    return CDMService.customersV2Client.getCustomersUsingGET(
      fetchPrevious,
      count,
      email,
      false,
      userGroupId,
      sortField,
      sortFieldCursor as any,
      sortFieldSortOrder,
      idField,
      idFieldCursor,
      idFieldSortOrder,
      firstName,
      false,
      lastName,
      false,
      externalId,
      organizationId,
      status,
      includeSystemUsers,
    );
  },
};
