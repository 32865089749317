import { cdmApi as api } from 'redux/slices/cdmApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    findCpoSubscriptionTariffUsingGet: build.query<
      FindCpoSubscriptionTariffUsingGetApiResponse,
      FindCpoSubscriptionTariffUsingGetApiArg
    >({
      query: queryArg => ({
        url: `/v1/cpo-subscription/tariff/${queryArg.cpoSubscriptionTariffId}`,
        params: { hashedEmpId: queryArg.hashedEmpId },
      }),
    }),
    findActiveCpoSubscriptionTariffsUsingGet: build.query<
      FindActiveCpoSubscriptionTariffsUsingGetApiResponse,
      FindActiveCpoSubscriptionTariffsUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v1/cpo-subscription/${queryArg.cpoId}/tariffs/${queryArg.orgId}` }),
    }),
    getCpoSubscriptionConfigurationUsingGet1: build.query<
      GetCpoSubscriptionConfigurationUsingGet1ApiResponse,
      GetCpoSubscriptionConfigurationUsingGet1ApiArg
    >({
      query: queryArg => ({
        url: `/v1/cpo-subscription/${queryArg.hashedOrgId}`,
        params: { activeOnly: queryArg.activeOnly },
      }),
    }),
    storeCpoSubscriptionConfigurationUsingPost: build.mutation<
      StoreCpoSubscriptionConfigurationUsingPostApiResponse,
      StoreCpoSubscriptionConfigurationUsingPostApiArg
    >({
      query: queryArg => ({
        url: `/v1/cpo-subscription/${queryArg.hashedOrgId}`,
        method: 'POST',
        body: queryArg.cpoSubscriptionConfig,
      }),
    }),
    getCpoSubscriptionConfigurationUsingGet: build.query<
      GetCpoSubscriptionConfigurationUsingGetApiResponse,
      GetCpoSubscriptionConfigurationUsingGetApiArg
    >({
      query: queryArg => ({ url: `/v1/cpo-subscription/${queryArg.hashedOrgId}/${queryArg.cpoSubscriptionTariffId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v1CpoSubscriptionApi };
export type FindCpoSubscriptionTariffUsingGetApiResponse = /** status 200 OK */ CpoSubscriptionConfigEntryResponse;
export type FindCpoSubscriptionTariffUsingGetApiArg = {
  /** cpoSubscriptionTariffId */
  cpoSubscriptionTariffId: string;
  /** hashedEmpId */
  hashedEmpId?: string;
};
export type FindActiveCpoSubscriptionTariffsUsingGetApiResponse =
  /** status 200 OK */ CpoSubscriptionConfigEntryResponse[];
export type FindActiveCpoSubscriptionTariffsUsingGetApiArg = {
  /** cpoId */
  cpoId: string;
  /** orgId */
  orgId: string;
};
export type GetCpoSubscriptionConfigurationUsingGet1ApiResponse = /** status 200 OK */ CpoSubscriptionConfig;
export type GetCpoSubscriptionConfigurationUsingGet1ApiArg = {
  /** hashedOrgId */
  hashedOrgId: string;
  /** activeOnly */
  activeOnly?: boolean;
};
export type StoreCpoSubscriptionConfigurationUsingPostApiResponse = /** status 200 OK */ CpoSubscriptionConfig;
export type StoreCpoSubscriptionConfigurationUsingPostApiArg = {
  /** hashedOrgId */
  hashedOrgId: string;
  /** cpoSubscriptionConfig */
  cpoSubscriptionConfig: CpoSubscriptionConfig;
};
export type GetCpoSubscriptionConfigurationUsingGetApiResponse = /** status 200 OK */ CpoSubscriptionConfigEntry;
export type GetCpoSubscriptionConfigurationUsingGetApiArg = {
  /** hashedOrgId */
  hashedOrgId: string;
  /** cpoSubscriptionTariffId */
  cpoSubscriptionTariffId: string;
};
export type OrganizationBase = {
  id: string;
  name: string;
};
export type CpoSubscriptionConfigEntryResponse = {
  cpo: OrganizationBase;
  description: string;
  id: string;
  name: string;
};
export type FieldConstraintViolation = {
  errorKey?:
    | 'MUST_BE_HIGHER'
    | 'MUST_BE_LOWER'
    | 'MUST_BE_NEGATIVE'
    | 'MUST_BE_NEGATIVE_OR_ZERO'
    | 'MUST_BE_POSITIVE'
    | 'MUST_BE_POSITIVE_OR_ZERO'
    | 'MUST_BE_UNIQUE'
    | 'MUST_BE_VALID'
    | 'MUST_BE_WITHIN_RANGE'
    | 'MUST_NOT_BE_EMPTY'
    | 'MUST_NOT_BE_NULL'
    | 'MUST_NOT_BE_NULL_OR_EMPTY'
    | 'MUST_NOT_BE_ZERO';
  field?: string;
  message?: string;
  rejectedValue?: object;
};
export type ErrorResponse = {
  code?: number;
  details?: string[];
  fieldViolations?: FieldConstraintViolation[];
  message?: string;
  status?: string;
  statusCode?: number;
};
export type CpoSubscriptionConfigEntry = {
  description: string;
  id?: string;
  name: string;
  roamingProviderIds: string[];
  validFrom: number;
  validTo: number;
};
export type CpoSubscriptionConfig = {
  subscriptions: CpoSubscriptionConfigEntry[];
  version: number;
};
export const {
  useFindCpoSubscriptionTariffUsingGetQuery,
  useFindActiveCpoSubscriptionTariffsUsingGetQuery,
  useGetCpoSubscriptionConfigurationUsingGet1Query,
  useStoreCpoSubscriptionConfigurationUsingPostMutation,
  useGetCpoSubscriptionConfigurationUsingGetQuery,
} = injectedRtkApi;
